

	import router from '../../router';
	import UserServices from '../../services/User';

	const response = JSON.parse(localStorage.getItem('user_session'));
	const message = '';	
	const user = null;
	const state = response
		? { status: { loggedIn: true }, response, message, user }
		: { status: { loggedIn: false }, response: null, message, user: null };

	// getters
	const getters = {}

	// actions
	const actions = {
		login({ commit }, { email, password }) {
			UserServices.userLogin(email, password)
				.then(
					response => {
						const userSession = response.data.data;
						localStorage.setItem('user_session', JSON.stringify(userSession));
						commit('loginSuccess', userSession);
						router.push('/timesheet');

						UserServices.getCurrenUserInfo()
						.then(
							response=>{
								const user = response.data.data;
								commit('setUserState', user);
							}
						);
					},
					error => {
						const errorMessage = error.data.message;
						commit('loginFailure', errorMessage);
					}
				);
		},
		setCurrentUser({ commit }){
			UserServices.getCurrenUserInfo()
			.then(
				response=>{
					const user = response.data.data;
					commit('setUserState', user);
				}
			);
		},
		logout({ commit }) {
			UserServices.userLogout()
			.then( 
				response=>{
					localStorage.removeItem('user_session');
					commit('logout');
				},
				error=>{
					const errorMessage = error.data.message;
					commit('logoutFailure', errorMessage);
				}
			);
		},
	}

	// mutations
	const mutations = {
		loginSuccess(state, response) {
			state.status = { loggedIn: true };
			state.response = response;
		},
		loginFailure(state, message) {
			state.status = { loggedIn: false };
			state.message = message;
			state.user = null;
		},
		logout(state, response) {
			state.status = { loggedIn: false };
			state.response = response;
			state.user = null;
		},
		logoutFailure(state, message) {
			state.status = { loggedIn: true };
			state.message = message;
		},
		setUserState(state, user){
			state.status = { loggedIn: true };
			state.user = user;
		}
	}

	export default {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	}