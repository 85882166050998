<template>
<v-app>
	<app-bar fixed @on-menu-click="drawer= !drawer" />
		<template v-if="status.loggedIn">
			<v-navigation-drawer
					v-model="drawer"
					absolute
					temporary
				>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-subtitle class="caption grey--text lighten-1">欢迎回来</v-list-item-subtitle>
						<v-list-item-title v-if="user">{{user.name}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn small text color="primary" @click="userLogout">
							<v-icon small left color="primary lighten-1">mdi-logout</v-icon>退出
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider></v-divider>
				<v-list	nav	dense>
					<v-list-item-group active-class="deep-purple--text text--accent-4">
						<v-list-item to="/timesheet">
							<v-list-item-avatar>
								<v-icon>
									mdi-calendar-range
								</v-icon>
							</v-list-item-avatar>
							<v-list-item-title>日程记录</v-list-item-title>
						</v-list-item>

						<v-list-item to="/project">
							<v-list-item-avatar>
								<v-icon>
									mdi-office-building
								</v-icon>
							</v-list-item-avatar>
							<v-list-item-title>项目信息</v-list-item-title>
						</v-list-item>

						<v-list-item to="/team">
							<v-list-item-avatar>
								<v-icon>
									mdi-account-hard-hat
								</v-icon>
							</v-list-item-avatar>
							<v-list-item-title>工作组管理</v-list-item-title>
						</v-list-item>
						<v-list-item to="/account" v-if="user && user.role === 'admin'">
							<v-list-item-avatar>
								<v-icon>
									mdi-account-box-multiple
								</v-icon>
							</v-list-item-avatar>
							<v-list-item-title>用户管理</v-list-item-title>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-navigation-drawer>
			<v-main>
				<router-view/>
			</v-main>
		</template>
		<template v-else>
			<v-main>
				<home />
			</v-main>
		</template>
</v-app>
</template>

<script>
import AppBar from '@/components/AppBar/AppBar';
import Home from './page/Home';

import { mapState, mapActions } from 'vuex';

export default {
		name: 'App',
		components: {
			AppBar,
			Home
		},
		data: () => ({
			drawer: false,
			sysmessage: '',
		}),
		watch: {
		},
		computed: {
			...mapState('user', ['status', 'message', 'user'],)
		},
		created(){
			if(localStorage.getItem('user_session') != null){
				this.getCurrentUserInfo();
			}
		},
		methods: {
			...mapActions('user', ['logout', 'setCurrentUser']),
			userLogout(){
				this.drawer = false;
				this.logout();
				this.sysmessage = this.message;
				if(this.$route.name != 'Home'){
					this.$router.push('/');
				}
			},
			getCurrentUserInfo(){
				this.setCurrentUser();
			}
		}
};
</script>
