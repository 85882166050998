import Api from '@/services/Api';

export default {
	userLogin(email, password) {
		const body = {
			"email": email,
			"password": password
		}
		return Api().post(`/authentication`, JSON.stringify(body));
	},
	userLogout(){
		return Api().post(`/authentication/revoke`);
	},
	getCurrenUserInfo(){
		return Api().get(`/userInfo`);
	},
	getUserList(page, itemsPerPage, isArchived){
		return Api().get(`/user?page=${page}&per_page=${itemsPerPage}&archived=${isArchived}`);
	},
	createUser(body){
		return Api().post(`/user`, JSON.stringify(body));
	},
	updateUser(user_id, body){
		return Api().put(`/user/${user_id}`, JSON.stringify(body));
	},
	getUserById(user_id){
		return Api().get(`/user/${user_id}`);
	}
}