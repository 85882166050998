<template>
	<v-container>
		<v-card
			class="mx-auto"
			max-width="800"
			outlined
		>
			<v-row no-gutters>
				<v-col cols="12" md="6">
					<v-carousel
						cycle
						height="350"
						hide-delimiter-background
						>
							<v-carousel-item
							src="@/assets/slides/slide_1.jpg"
							reverse-transition="fade-transition"
							transition="fade-transition"
							></v-carousel-item>
							<v-carousel-item
							src="@/assets/slides/slide_2.jpg"
							reverse-transition="fade-transition"
							transition="fade-transition"
							></v-carousel-item>
							<v-carousel-item
							src="@/assets/slides/slide_3.jpg"
							reverse-transition="fade-transition"
							transition="fade-transition"
							></v-carousel-item>
					</v-carousel>
				</v-col>
				<v-col cols="12" md="6">
					<div class="pt-3 px-6">
						<account-login />
					</div>
					<div class="text-center caption pt-3 pb-1 grey--text lighten-3">
						© COPYRIGHT 2021, EMPIRE CBS
					</div>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
import AccountLogin from '../components/AccountLogin'

export default {
	name: 'Home',
	components: {
		AccountLogin,
	},
	data: () => ({
	})
}
</script>
